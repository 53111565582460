<template>
  <div>
    <MallheaderVue :titlename="'评论'"></MallheaderVue>
    <div class="comment">
      <div class="tab">
        <van-tabs v-model="tabvalue" @change="changeTab">
          <div v-for="(tab, index) in tabsarr" :key="index">
            <van-tab :title="tab">
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <div class="comcard">
                  <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                  >
                    <div v-for="(item, index) in list" :key="index">
                      <div v-if="item.is_show != 0" class="carditem">
                        <div class="topview">
                          <div class="img"></div>
                          <div class="name">Dosence</div>
                          <div class="star">
                            <img
                              v-for="i in item.goods_rank"
                              class="staricon"
                              :key="i"
                              :src="
                                require('@/assets/images/mall/starorange.png')
                              "
                            />
                          </div>
                        </div>
                        <div class="destxt van-multi-ellipsis--l2">
                          {{ item.content }}
                        </div>
                        <div class="line"></div>
                      </div>
                    </div>
                  </van-list>
                </div>
              </van-pull-refresh>
            </van-tab>
          </div>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getCommentList } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
export default {
  name: "EtWebCommentlist",
  components: {
    MallheaderVue,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      tabvalue: 0,
      tabsarr: ["好评", "差评"],
      goodid: "",
      currentpage: 1,
    };
  },

  mounted() {},
  activated() {
    this.goodid = this.$route.query.id;
    this.onRefresh();
  },
  methods: {
    changeTab() {
      this.onRefresh();
    },
    requestCommentList() {
      const params = {
        good_id: this.goodid,
        status: this.tabvalue + 1,
        page: this.currentpage,
        limit: 20,
      };
      getCommentList(params).then((res) => {
        let goodCount = res.data.goodCount;
        if (goodCount > 1) {
          this.tabsarr[0] = "好评(" + res.data.goodCount + ")";
        }
        let badCount = res.data.badCount;
        if (badCount > 1) {
          this.tabsarr[1] = "差评(" + res.data.badCount + ")";
        }
        if (this.refreshing) {
          this.refreshing = false;
        }
          this.list = [ ...res.data.list];
        this.loading = false;

        if (this.list.length < 20) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      this.requestCommentList();
        // this.currentpage++;

    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.currentpage = 1;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  background: #ebeef2;
  min-height: 90vh;
  .tab {
    ::v-deep .van-tabs__nav {
      background-color: transparent;
    }
    ::v-deep .van-tabs__line {
      width: 28px;
      height: 4px;
      border-radius: 4px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      bottom: 20px;
    }
    .comcard {
      min-height: 700px;
      margin-top: 24px;
      .carditem {
        width: 750px;
        height: 184px;
        background: white;
        padding: 24px 32px;
        .topview {
          display: flex;
          align-items: center;
          .img {
            width: 48px;
            height: 48px;
            border-radius: 24px;
            background-color: orange;
            margin-right: 16px;
          }
          .name {
            color: rgba(52, 52, 52, 1);
            font-size: 24px;
            font-weight: 700;
            font-family: "PingFang SC";
            margin-right: 8px;
          }
          .star {
            display: flex;
            .staricon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
        }
        .destxt {
          margin-top: 16px;
          color: rgba(105, 98, 95, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
        .line {
          position: relative;
          bottom: -22px;
          width: 686px;
          height: 2px;
          border-radius: 2px;
          opacity: 1;
          background: rgba(230, 224, 220, 1);
        }
      }
    }
  }
}
</style>
